import { render, staticRenderFns } from "./ProgramPage.vue?vue&type=template&id=0671fa75&scoped=true&"
import script from "./ProgramPage.vue?vue&type=script&lang=js&"
export * from "./ProgramPage.vue?vue&type=script&lang=js&"
import style0 from "./ProgramPage.vue?vue&type=style&index=0&id=0671fa75&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0671fa75",
  null
  
)

export default component.exports
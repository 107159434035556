<template>
  <div class="admin-layout">
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script >
export default {
  name: "AdminLayout",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>


<style scoped>



</style>
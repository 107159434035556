<template>
  <div class="wholePage">
    <DButton href="/teacher/add-program" class="">Add Program</DButton>
  </div>
</template>

<script>
import DButton from "@/components/DButton.vue";

export default {
  name: "TeacherDashboard",
  components: {
    DButton
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./AddDocumentForm.vue?vue&type=template&id=d5bb7af4&scoped=true&"
import script from "./AddDocumentForm.vue?vue&type=script&lang=js&"
export * from "./AddDocumentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5bb7af4",
  null
  
)

export default component.exports
<template>
  <div class="wholePage">
    <DButton href="/admin/register-teacher" class="">Register Teacher</DButton>
  </div>
</template>

<script>
import DButton from "@/components/DButton.vue";

export default {
  name: "AdminDashboard",
  components: {
    DButton
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
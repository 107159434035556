var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionnaires-wrapper"},[_c('h1',[_vm._v("Questionnaires")]),_vm._l((_vm.questionnaires),function(questionnaire){return _c('div',{key:questionnaire.id,staticClass:"questionnaire"},[_c('p',[_vm._v(_vm._s(questionnaire.title))]),(_vm.isStudent)?_c('router-link',{attrs:{"to":{
        name: 'QuestionnaireDetails',
        params: { questionnaireId: questionnaire.id },
      }}},[_vm._v("Détails")]):_vm._e(),(_vm.isTeacher)?_c('router-link',{attrs:{"to":{
        name: 'TeacherQuestionnairePage',
        params: { questionnaireId: questionnaire.id },
      }}},[_vm._v("Détails")]):_vm._e(),(_vm.isAdmin)?_c('router-link',{attrs:{"to":{
        name: 'AdminQuestionnairePage',
        params: { questionnaireId: questionnaire.id },
      }}},[_vm._v("Détails")]):_vm._e(),(_vm.isAdmin || _vm.isTeacher)?_c('div',[_c('v-btn',{staticClass:"deleteButton",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteQuestionnaire(questionnaire.id)}}},[_vm._v("Supprimer")]),(questionnaire.is_visible === 0)?_c('v-btn',{staticClass:"startButton",attrs:{"color":"primary"},on:{"click":function($event){return _vm.publish(questionnaire.id)}}},[_vm._v("Rendre accéssible")]):_c('v-btn',{staticClass:"startButton",attrs:{"color":"error"},on:{"click":function($event){return _vm.unPublish(questionnaire.id)}}},[_vm._v("Rendre inaccéssible")])],1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
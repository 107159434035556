<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script >
export default {
  name: "StudentLayout",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style scoped>

</style>